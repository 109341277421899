import React, { useCallback, useEffect, useState } from 'react';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import RealTimeLogInProgressView from './real-time-log-in-progress.view';

type IProps = {
  currentTab: number;
  timer?: any;
  showTableAll: boolean;
  updatedInProgressLog: number | null;
  setUpdatedInProgressLog: React.Dispatch<React.SetStateAction<number | null>>;
};

const RealtimeLogInProgress: React.FC<IProps> = ({
  currentTab,
  timer,
  showTableAll,
  updatedInProgressLog,
  setUpdatedInProgressLog,
}) => {
  const { state, actions } = useRealTimeLogContext();
  const [isEditOpen, setIsEditOpen] = useState<boolean | null>(false);
  const [manualRefresh, setManualRefresh] = useState<boolean>(false);

  const statusInProgressRead = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.REAL_TIME_LOG_INPROGRESS_LIST_READ
  );

  useEffect(() => {
    const fetchData = async () => {
      const result = await actions.listInProgressGET();
      if (result.error) {
        ToastError('Failed to retrieve In Progress Logs.');
      }
    };

    fetchData();

    return () => {
      actions.clearInProgressList();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await actions.listInProgressGET();
      if (result.error) {
        ToastError('Failed to retrieve In Progress Logs.');
      }
    };

    !isEditOpen && fetchData();
  }, [isEditOpen, updatedInProgressLog]);

  const refresh = useCallback(async () => {
    const result = await actions.listInProgressGET();

    if (result.error) {
      ToastError('Failed to retrieve In Progress Forced Outages.');
    }
    setManualRefresh(false);
  }, [actions, setManualRefresh]);

  return (
    <RealTimeLogInProgressView
      data={state.inProgress}
      count={state.inProgressCnt}
      loading={statusInProgressRead.fetching}
      currentTab={currentTab}
      timer={timer}
      refresh={refresh}
      showTableAll={showTableAll}
      setIsEditOpen={setIsEditOpen}
      setUpdatedInProgressLog={setUpdatedInProgressLog}
      manualRefresh={manualRefresh}
      setManualRefresh={setManualRefresh}
    />
  );
};

export default RealtimeLogInProgress;
