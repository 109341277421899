/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useStatusTab from 'src/hooks/status-tab.hook';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import {
  getRealTimeLogAuditParams,
  getRealTimeLogAuditStatus,
} from 'src/selectors/real-time-log-audit.selector';
import { useRealTimeLogAuditContext } from 'src/contexts/real-time-log-audit.context';
import { realTimeLogAuditActionTypes } from 'src/ducks/real-time-log-audit.duck';
import { checkValue } from 'src/libraries/common.library';
import { formatToRawEmployeeId } from 'src/helpers/employee.helper';
import Moment, { formatDateTime } from 'src/libraries/moment.library';
import {
  buildExportDataForRealTimeLog,
  convertToExcel,
} from 'src/selectors/file.selector';
import Lang from 'src/libraries/language';
import RealTimeLogAuditListView from './real-time-log-audit-list.view';

const RealTimeLogAuditList: React.FC = () => {
  const { state, actions } = useRealTimeLogAuditContext();
  const { currentTab } = useStatusTab('realTimeLogAuditStatus');
  const auditLogsReadState = getRealTimeLogAuditStatus(
    state,
    realTimeLogAuditActionTypes.BPOR_AUDIT_LOGS_READ
  );
  const downloadStatus = getRealTimeLogAuditStatus(
    state,
    realTimeLogAuditActionTypes.BPOR_AUDIT_LOGS_DOWNLOAD_READ
  );

  const formRef = useRef<HTMLFormElement>(null);
  const [showAuditTableAll, setShowAuditTableAll] = useState(false);
  const [isAuditSearch, setIsAuditSearch] = useState(false);
  const [auditSearchFilters, setAuditSearchFilters] = useState<
    Record<any, any>
  >({});
  const [showAuditAll, setShowAuditAll] = useState(true);
  const [totalAll, setTotalAll] = useState(0);

  useEffect(() => {
    setTotalAll(0);
    // Reset filters when currentTab changes
    if (!currentTab || currentTab === 0) {
      setShowAuditTableAll(false);
      setAuditSearchFilters({ page: 1, limit: 0 });
    } else {
      setAuditSearchFilters({
        sort: undefined,
        page: 1,
        filters: [{ name: 'isAll', value: true }],
      });
    }

    actions.clearList();
  }, [currentTab, actions]);

  const handleFetch = useCallback(
    async (params) => {
      const data = getRealTimeLogAuditParams(
        params.limit === 0 || params.limit === 99999
          ? { ...params, sort: undefined, order: undefined }
          : {
              ...params,
              page: params.page,
              filters: params.filters,
              limit: 0,
            },
        currentTab
      );

      setAuditSearchFilters(data);
      setIsAuditSearch(false);

      const response = await actions.auditLogsGET(data);

      if (response.payload) {
        if (currentTab === 1) {
          setTotalAll(response.payload?.count?.all ?? 0);
        } else {
          setTotalAll(0);
        }
      }

      if (response.error) {
        ToastError('Error retrieving Real-Time Audit Logs.');
      }
    },
    [actions, currentTab, setTotalAll]
  );

  const handleAll = useCallback(
    (params) => {
      const data = getRealTimeLogAuditParams(params, currentTab);
      actions?.auditLogsGET(data);
    },
    [actions, currentTab]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      setIsAuditSearch(true);
      formRef.current.handleSubmit();
    } else {
      setIsAuditSearch(false);
    }
  }, [formRef, setIsAuditSearch]);

  const handleSubmit = useCallback(
    async (formData) => {
      if (isAuditSearch) {
        const data = getRealTimeLogAuditParams(
          {
            page: 1,
            filters: Object.entries(formData).map(([key, value]) => {
              return {
                name: key,
                value,
              };
            }),
          },
          1
        );
        setAuditSearchFilters(data);
        setShowAuditTableAll(true);
        const response = await actions?.auditLogsGET(data);
        if (response.payload) {
          if (currentTab === 1) {
            setTotalAll(response.payload?.count?.all ?? 0);
          } else {
            setTotalAll(0);
          }
        }
        if (response.error) {
          ToastError('Error retrieving Real-Time Log Audit.');
        }
      }
    },
    [
      actions,
      formRef,
      currentTab,
      setTotalAll,
      setShowAuditTableAll,
      showAuditTableAll,
      isAuditSearch,
      setAuditSearchFilters,
    ]
  );

  useEffect(() => {
    return () => {
      actions.clearList();
    };
  }, [actions]);

  const handleDownload = useCallback(async () => {
    const response = await actions?.downloadAuditLogsGET({
      ...auditSearchFilters,
      limit: 0,
    });

    if (response.payload?.rows) {
      const list = response.payload?.rows;

      const items = list.map((value) => {
        return [
          checkValue(value.logId ?? '--'),
          checkValue(value.auditType ?? '--'),
          checkValue(value.auditEntry ?? '--'),
          checkValue(formatDateTime(value.updatedAt)),
          checkValue(
            `${
              value.emp
                ? value.emp.lastName && value.emp.firstName
                  ? `${value.emp.lastName}, ${value.emp.firstName}`
                  : `Unavailable (${formatToRawEmployeeId(value.updatedBy)})`
                : `Unavailable (${formatToRawEmployeeId(value.updatedBy)})`
            }`
          ),
        ];
      });
      const { exportData, format } = convertToExcel(
        buildExportDataForRealTimeLog(items, [
          Lang.LBL_LOG_ID,
          Lang.LBL_AUDIT_TYPE,
          Lang.LBL_AUDIT_ENTRY,
          Lang.LBL_AUDIT_TIMESTAMP,
          Lang.LBL_AUDIT_BY,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Forced_Outage_Email_Audit_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [actions, auditSearchFilters]);

  return (
    <RealTimeLogAuditListView
      data={state.list}
      loading={auditLogsReadState.fetching}
      handleFetch={handleFetch}
      handleAll={handleAll}
      total={totalAll}
      today={state.today}
      currentTab={currentTab}
      formRef={formRef}
      handleSubmit={handleSubmit}
      handleClick={handleClick}
      showAuditTableAll={showAuditTableAll}
      handleDownload={handleDownload}
      setIsAuditSearch={setIsAuditSearch}
      auditSearchFilters={auditSearchFilters}
      setShowAuditAll={setShowAuditAll}
      showAuditAll={showAuditAll}
      setAuditSearchFilters={setAuditSearchFilters}
      downloadStatus={downloadStatus.fetching}
      setShowAuditTableAll={setShowAuditTableAll}
      setTotalAll={setTotalAll}
    />
  );
};

export default RealTimeLogAuditList;
