import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import {
  ForcedOutageConfirmationFormSchema,
  IForcedOutageConfirmationForm,
  IRealTimeLogEntryForm,
} from 'src/models/real-time-log.model';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import Lang from 'src/libraries/language';
import history from 'src/history';
import { RealTimeLogCategory } from 'src/constants/real-time-log.constant';
import { hasRole } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';
import RealTimeLogAddView from './reat-time-log-add.view';

type IProp = {
  data?: Partial<IRealTimeLogEntryForm>;
  currentTab?: number;
  timer?: any;
  // setUpdatedInProgressLog: React.Dispatch<React.SetStateAction<number | null>>;
  // setIsAddOpen: React.Dispatch<React.SetStateAction<boolean | null>>;
  // isAddOpen: boolean | null;
};

const RealTimeLogAdd: React.FC<IProp> = ({
  currentTab,
  timer,
  // setUpdatedInProgressLog,
  // isAddOpen,
  // setIsAddOpen,
}) => {
  const { state, actions } = useRealTimeLogContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { actions: equipmentTypeActions } = useEquipmentTypeContext();
  const [isAddOpen, setIsAddOpen] = useState<boolean | null>(null);
  const [isForcedOutageConfirmOpen, setIsForcedOutageConfirmOpen] =
    useState(false);
  const [tripOnEdit, setTripOnEdit] = useState<{
    index: number;
    logSub: string;
    tripsTargets: string;
  } | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const formRefEmail = useRef<HTMLFormElement>(null);

  const status = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.REAL_TIME_LOG_DATA_CREATE
  );

  const sendNowStatus = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.FORCED_OUTAGE_SEND_NOW
  );

  const sendLaterStatus = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.FORCED_OUTAGE_SEND_LATER
  );

  useEffect(() => {
    if (isAddOpen) {
      if (
        !substationState.listForCreate ||
        substationState.listForCreate.length === 0
      ) {
        substationActions.listSubstationCreateGET({ limit: 0, page: 1 });
      }

      if (!state.line || state.line?.length === 0) {
        actions.lineCreateGET();
      }

      if (timer && timer.current) {
        clearTimeout(timer.current);
      }
      equipmentTypeActions?.listGET({ limit: 0, page: 1 });
    }

    if (isAddOpen && (!state.line || state.line?.length === 0)) {
      actions.lineCreateGET();
    }

    if (isAddOpen === false) {
      history.push('/real-time-log?realTimeLogStatus=0');
    }
  }, [isAddOpen]);

  const handleConfirmEmailClick = useCallback(() => {
    if (formRefEmail && formRefEmail.current) {
      formRefEmail.current.handleSubmit();
    }
  }, [formRefEmail]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  // Only handleSubmit is working for some reason?!? Other custom name is not working so let's allow two
  // types of formData and call schema.validate() to know the type and perform processing based on that.
  const handleSubmit = useCallback(
    async (formData: IRealTimeLogEntryForm | IForcedOutageConfirmationForm) => {
      let isConfirm;
      await ForcedOutageConfirmationFormSchema.validate(formData)
        .then(async (validFormData) => {
          isConfirm = true;
          const response = await actions.handleSendEmailNow(validFormData);

          if (!response.error) {
            setIsForcedOutageConfirmOpen(false);
            setIsAddOpen(false);
            ToastSuccess(Lang.MSG_FORCED_OUTAGE_EMAIL_SENT);
          } else {
            ToastError(Lang.MSG_FORCED_OUTAGE_EMAIL_ERROR);
          }
        })
        .catch((error) => {
          isConfirm = false;
          return error;
        });
      if (isConfirm === false) {
        const updatedForm: IRealTimeLogEntryForm =
          formData as IRealTimeLogEntryForm;
        // if (updatedForm.logCategory !== RealTimeLogCategory.FORCEDOUTAGE) {
        //   updatedForm.logDtTmFrcdOutOccurred = null;
        //   updatedForm.logDtTmFrcdOutRestored = null;
        //   updatedForm.logTmFrcdOutOccurred = null;
        //   updatedForm.logTmFrcdOutRestored = null;
        // } else {
        if (updatedForm.logDtTmFrcdOutOccurred) {
          updatedForm.logDtTmFrcdOutOccurred = new Date(
            updatedForm.logDtTmFrcdOutOccurred?.getFullYear(),
            updatedForm.logDtTmFrcdOutOccurred?.getMonth(),
            updatedForm.logDtTmFrcdOutOccurred?.getDate(),
            Number(updatedForm.logTmFrcdOutOccurred?.split(':')[0]),
            Number(updatedForm.logTmFrcdOutOccurred?.split(':')[1])
          );
        }

        if (updatedForm.logDtTmFrcdOutRestored) {
          updatedForm.logDtTmFrcdOutRestored = new Date(
            updatedForm.logDtTmFrcdOutRestored?.getFullYear(),
            updatedForm.logDtTmFrcdOutRestored?.getMonth(),
            updatedForm.logDtTmFrcdOutRestored?.getDate(),
            Number(updatedForm.logTmFrcdOutRestored?.split(':')[0]),
            Number(updatedForm.logTmFrcdOutRestored?.split(':')[1])
          );
        }
        // }

        updatedForm.logBporReport = updatedForm.logBporReport ?? false;

        updatedForm.logDtTm = new Date(
          updatedForm.logDtTm.getFullYear(),
          updatedForm.logDtTm.getMonth(),
          updatedForm.logDtTm.getDate(),
          Number(updatedForm.logTm.split(':')[0]),
          Number(updatedForm.logTm.split(':')[1])
        );

        if (tripOnEdit) {
          updatedForm.forcedLog[tripOnEdit.index].logSub = tripOnEdit.logSub;
          updatedForm.forcedLog[tripOnEdit.index].tripsTargets =
            tripOnEdit.tripsTargets;
        }

        setTripOnEdit(null);

        const response = await actions.createPOST(updatedForm);

        if (!response.error) {
          ToastSuccess(Lang.MSG_REAL_TIME_LOG_ADD);

          if (
            (updatedForm.logCategory === RealTimeLogCategory.FORCEDOUTAGE ||
              updatedForm.logCategory === RealTimeLogCategory.RASOPERATION ||
              (updatedForm.logCategory === RealTimeLogCategory.WILDFIRE &&
                updatedForm.logBporReport)) &&
            hasRole(AccessRole.BPOR_SENDER)
          ) {
            setIsForcedOutageConfirmOpen(true);
          } else {
            setIsAddOpen(false);
          }

          // It is possible that when adding a forced outage or rcbanotif, inProgressFlag is
          // if (
          //   [
          //     RealTimeLogCategory.FORCEDOUTAGE,
          //     RealTimeLogCategory.RCBANOTIF,
          //   ].includes(updatedForm.logCategory as RealTimeLogCategory) &&
          //   response.payload?.logInProgress
          // ) {
          //   setUpdatedInProgressLog(updatedForm.logId as number);
          // }
        } else {
          ToastError(
            'Error creating Real Time Log. Please contact your system administrator.'
          );
        }
      }
    },
    [actions, currentTab]
  );

  const handleSendLater = useCallback(
    async (logId: number) => {
      const response = await actions.handleSendEmailLater(logId);
      if (response.error) {
        ToastSuccess(Lang.MSG_FORCED_OUTAGE_EMAIL_SEND_LATER_ERROR);
      }
      setIsForcedOutageConfirmOpen(false);
      setIsAddOpen(false);
    },
    [actions, currentTab]
  );

  return (
    <RealTimeLogAddView
      formRef={formRef}
      formRefEmail={formRefEmail}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      handleConfirmEmailClick={handleConfirmEmailClick}
      handleConfirmEmailSubmit={handleSubmit}
      isAddOpen={isAddOpen}
      data={state.data}
      handleOpen={setIsAddOpen}
      setTripOnEdit={setTripOnEdit}
      isForcedOutageConfirmOpen={isForcedOutageConfirmOpen}
      setIsForcedOutageConfirmOpen={setIsForcedOutageConfirmOpen}
      handleSendLater={handleSendLater}
      sendNowLoading={sendNowStatus.fetching}
      sendLaterLoading={sendLaterStatus.fetching}
    />
  );
};

export default RealTimeLogAdd;
