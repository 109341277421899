import React, { useRef, useState } from 'react';
import { ATMModal, ATMButton } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import RealTimeLogForm from 'src/components/pages/real-time-log/real-time-log-form/real-time-log-form.component';
import {
  IRealTimeLogEntryForm,
  IRealTimeLog,
  IForcedOutageConfirmationForm,
} from 'src/models/real-time-log.model';
import { generateRealTimeLogEmailFormDetails } from 'src/helpers/real-time-log.helper';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import RealTimeLogMailConfirmationForm, {
  ConfirmationSource,
} from '../real-time-log-form/forced-outage-email/real-time-log-email-confirmation-form';
import { RealTimeLogCategory } from 'src/constants/real-time-log.constant';

type IProps = {
  isAddOpen: boolean | null;
  loading: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  formRefEmail?: React.RefObject<HTMLFormElement>;
  handleClick: () => void;
  handleOpen: React.Dispatch<React.SetStateAction<boolean | null>>;
  handleSubmit: (data: IRealTimeLogEntryForm) => void;
  handleConfirmEmailClick: () => void;
  handleConfirmEmailSubmit: (data: IForcedOutageConfirmationForm) => void;
  data?: IRealTimeLog;
  setTripOnEdit: React.Dispatch<
    React.SetStateAction<{
      index: number;
      logSub: string;
      tripsTargets: string;
    } | null>
  >;
  isForcedOutageConfirmOpen: boolean;
  setIsForcedOutageConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSendLater: (logId: number) => void;
  sendNowLoading: boolean;
  sendLaterLoading: boolean;
};

const RealTimeLogAddView: React.FC<IProps> = ({
  isAddOpen,
  loading,
  formRef,
  formRefEmail,
  handleOpen,
  handleClick,
  handleSubmit,
  handleConfirmEmailClick,
  handleConfirmEmailSubmit,
  data,
  setTripOnEdit,
  isForcedOutageConfirmOpen,
  setIsForcedOutageConfirmOpen,
  handleSendLater,
  sendNowLoading,
  sendLaterLoading,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  // const [isConfirmDirty, setIsConfirmDirty] = useState(false);
  const resetRef = useRef<any>();
  if (resetRef.current && loading === true) {
    resetRef.current();
  }
  const { state: realTimeLogState } = useRealTimeLogContext();

  return (
    <ATMModal
      open={isAddOpen ?? false}
      size="small"
      trigger={
        <ATMButton
          content="Add entry"
          onClick={() => handleOpen(true)}
          primary
          style={{ margin: 0 }}
        />
      }
    >
      <ATMModal.Header>{Lang.TTL_NEW_ENTRY_LOG}</ATMModal.Header>
      <ATMModal.Content style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <div>
          {isForcedOutageConfirmOpen && realTimeLogState.data && (
            <RealTimeLogMailConfirmationForm
              formRefEmail={formRefEmail}
              handleConfirmEmailSubmit={handleConfirmEmailSubmit}
              handleConfirmEmailClick={handleConfirmEmailClick}
              isForcedOutageConfirmOpen={isForcedOutageConfirmOpen}
              setIsForcedOutageConfirmOpen={setIsForcedOutageConfirmOpen}
              handleSendLater={handleSendLater}
              logId={realTimeLogState.data.logId}
              sendNowLoading={sendNowLoading}
              sendLaterLoading={sendLaterLoading}
              mailFormDetails={
                realTimeLogState.data
                  ? generateRealTimeLogEmailFormDetails(realTimeLogState.data)
                  : undefined
              }
              confirmationSource={ConfirmationSource.ADD_ENTRY_LOG}
              isForcedOutage={
                realTimeLogState.data.logCategory ===
                RealTimeLogCategory.FORCEDOUTAGE
              }
              handleOpen={handleOpen}
            />
          )}
          <RealTimeLogForm
            formRef={formRef}
            handleSubmit={handleSubmit}
            handleEnable={(value) => setIsDirty(value)}
            defaultValues={{
              logUserType: data?.logUserType,
            }}
            setTripOnEdit={setTripOnEdit}
          />
        </div>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => handleOpen(false)}
        />
        <ATMButton
          primary
          content="Save"
          disabled={!isDirty || loading}
          onClick={handleClick}
          loading={loading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default RealTimeLogAddView;
