import React, { useState } from 'react';
import {
  ATMButton,
  ATMDatePicker,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMGrid,
} from 'shared-it-appmod-ui';
import Moment from 'src/libraries/moment.library';
import Lang from 'src/libraries/language';
import {
  IRealTimeLogAuditAll,
  RealTimeLogAuditAllSchema,
} from 'src/models/real-time-log-audit.model';
import style from './real-time-log-audit-all-search.module.scss';
// import { useRealTimeLogAuditContext } from 'src/contexts/real-time-log-audit.context';

type IProps = {
  formRef: React.RefObject<HTMLFormElement>;
  handleSubmit: (data: IRealTimeLogAuditAll) => void;
  setShowAuditTableAll: (x: boolean) => void;
  handleClick: () => void;
  setIsAuditSearch: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAuditAll: React.Dispatch<React.SetStateAction<boolean>>;
  showAuditAll: boolean;
  loading: boolean;
  setTotalAll: React.Dispatch<React.SetStateAction<number>>;
};

const RealTimeLogAuditAllSearch: React.FC<IProps> = ({
  formRef,
  handleSubmit,
  handleClick,
  // employees,
  setShowAuditTableAll,
  setIsAuditSearch,
  setShowAuditAll,
  showAuditAll,
  loading,
  setTotalAll,
}) => {
  const [dateRange, setDateRange] = useState<Date>(new Date());
  const handleDateRange = (date: Date) => {
    const dateLimit = Moment(date).add(6, 'months');
    setDateRange(dateLimit.isAfter(Moment()) ? new Date() : dateLimit.toDate());
  };

  return (
    <div className={style.filterContainer}>
      <ATMForm
        ref={formRef}
        onSubmit={handleSubmit}
        mode="onChange"
        validationSchema={RealTimeLogAuditAllSchema}
      >
        {({ control, formState: { errors }, reset, clearErrors }) => {
          return (
            <ATMGrid>
              <ATMGrid.Row
                columns={4}
                style={{
                  marginBottom: errors && errors.updatedAt ? '2em' : '0',
                  paddingBottom: '0',
                }}
              >
                <ATMGrid.Column
                  width={4}
                  className={style.selectables}
                  style={{ paddingRight: '1em' }}
                >
                  <ATMField
                    as={ATMDatePicker}
                    name="updatedAt"
                    type="range"
                    format="MM/DD/YYYY"
                    maxDate={dateRange}
                    control={control}
                    error={errors.updatedAt}
                    keepFocus
                    onChange={([_, { value }]) => {
                      setIsAuditSearch(false);
                      if (value) {
                        handleDateRange(value[0]);
                      } else {
                        setDateRange(new Date());
                      }
                      return value ?? undefined;
                    }}
                    showToday={false}
                    required
                  />
                </ATMGrid.Column>
                <ATMGrid.Column style={{ paddingLeft: '0' }} width={4}>
                  <ATMField
                    as={ATMDropdown}
                    selection
                    clearable
                    name="auditType"
                    placeholder="Audit Type"
                    control={control}
                    error={errors.auditType}
                    style={{ display: 'block', marginRight: '1em' }}
                    options={[
                      ...Array.from(
                        Object.keys(Lang.REAL_TIME_LOG_AUDIT_CATEGORY)
                      ).map((val, i) => {
                        return {
                          key: i,
                          value: val,
                          text: Lang.REAL_TIME_LOG_AUDIT_CATEGORY[val],
                        };
                      }),
                    ]}
                    onChange={([_, { value }]) => {
                      setIsAuditSearch(false);
                      return value ?? undefined;
                    }}
                    search
                    selectOnBlur={false}
                  />
                </ATMGrid.Column>
                {/* <ATMGrid.Column style={{ paddingLeft: '0' }} width={3}>
                  <ATMField
                    as={ATMInput}
                    name="updatedBy"
                    placeholder="Entry text"
                    control={control}
                    error={errors.updatedBy}
                    onChange={([_, { value }]) => {
                      setIsAuditSearch(false);
                      return value ?? undefined;
                    }}
                    clearable
                  />
                </ATMGrid.Column> */}
              </ATMGrid.Row>
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <ATMButton
                    primary
                    content="Search"
                    onClick={() => handleClick()}
                    type="submit"
                    loading={loading}
                  />
                  <ATMButton
                    secondary
                    content="Clear Fields"
                    type="button"
                    style={{ marginLeft: '1em' }}
                    onClick={() => {
                      reset({
                        auditType: '',
                        updatedAt: [],
                      });

                      setImmediate(() => {
                        clearErrors();
                        setTotalAll(0);
                        setShowAuditTableAll(false);
                        setIsAuditSearch(false);
                        setShowAuditAll(!showAuditAll);
                      });
                    }}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default RealTimeLogAuditAllSearch;
