/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import {
  ATMGrid,
  ATMField,
  ATMForm,
  ATMTextArea,
  ATMInput,
  ATMFormProvider,
  useATMFormContext,
  ATMButton,
  ATMModal,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  ForcedOutageConfirmationFormSchema,
  IForcedOutageConfirmationForm,
  IRealTimeLog,
} from 'src/models/real-time-log.model';
import { isEqual } from 'lodash';
import style from '../real-time-log-form.module.scss';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { generateRealTimeLogEmailFormDetails } from 'src/helpers/real-time-log.helper';
import { RealTimeLogCategory } from 'src/constants';

export enum ConfirmationSource {
  ENABLE_EMAIL = 'Enable Email',
  TURNED_OFF_EMAIL = 'Turn off Email',
  UPDATE_ENTRY_LOG = 'Update Entry Log',
  ADD_ENTRY_LOG = 'Add Entry Log',
  SEND_RESEND_EMAIL = 'Send/Resend Email',
  IN_PROGRESS_UPDATE = 'In Progress Update',
}

type IProp = {
  formRefEmail?: React.RefObject<HTMLFormElement>;
  // defaultValues?: Partial<IRealTimeLog>;
  handleConfirmEmailSubmit?: (data: IForcedOutageConfirmationForm) => void;
  handleConfirmEmailClick?: () => void;
  isForcedOutageConfirmOpen?: boolean;
  setIsForcedOutageConfirmOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSendLater?: (logId: number) => void;
  logId: number;
  isClearNoticeConfirmOpen?: boolean;
  setIsClearNoticeConfirmOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  clearForcedOutageNotice?: (logId: number) => void;
  sendNowLoading?: boolean;
  sendLaterLoading?: boolean;
  turnOffEmailLoading?: boolean;
  mailFormDetails?: Partial<IForcedOutageConfirmationForm>;
  isOnEdit?: boolean;
  frcdOutgEmailSent?: boolean | null;
  enableEmailNotice?: (logId: number) => void;
  setIsEnableConfirmOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isEnableConfirmOpen?: boolean;
  enableEmailLoading?: boolean;
  handleOpen?: React.Dispatch<React.SetStateAction<boolean | null>>;
  confirmationSource: ConfirmationSource;
  handleInProgressOpen?: React.Dispatch<React.SetStateAction<boolean | null>>;
  isForcedOutage?: boolean;
};

type IFormContent = {
  frcdOutgEmailSent?: boolean | null;
  sendNowLoading?: boolean;
  sendLaterLoading?: boolean;
};

const FormContent: React.FC<IFormContent> = ({
  frcdOutgEmailSent,
  sendNowLoading,
  sendLaterLoading,
}) => {
  const {
    formState: { errors },
    control,
  } = useATMFormContext<IForcedOutageConfirmationForm>();
  return (
    <ATMGrid>
      <ATMGrid.Row style={{ paddingBottom: '0em' }}>
        <ATMGrid.Column>
          {frcdOutgEmailSent
            ? Lang.LBL_RESEND_FORCED_OUTAGE_CONFIRM
            : Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM}
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <ATMField
            control={control}
            as={ATMInput}
            name="logId"
            type="hidden"
            className="hidden"
          />
          <ATMField
            control={control}
            as={ATMTextArea}
            name="mailHistoryContent"
            type="hidden"
            className="hidden"
          />
          <ATMField
            control={control}
            as={ATMInput}
            name="mailSubject"
            error={errors.mailSubject}
            autoComplete="off"
            maxLength={100}
            placeholder="Format: Real-Time Interruption: <subject details>"
            disabled={sendNowLoading || sendLaterLoading}
            label={
              <span className={style.emphasizedSmall}>
                You may edit the details before sending
              </span>
            }
            onChange={([_, { value }]) => value}
          />
          <ATMField
            as={ATMTextArea}
            name="mailContent"
            maxLength={2500}
            control={control}
            error={errors.mailContent}
            rows={5}
            disabled={sendNowLoading || sendLaterLoading}
            autoComplete="off"
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const RealTimeLogMailConfirmationForm: React.FC<IProp> = ({
  formRefEmail,
  handleConfirmEmailSubmit,
  handleConfirmEmailClick,
  isForcedOutageConfirmOpen,
  setIsForcedOutageConfirmOpen,
  handleSendLater,
  logId,
  sendNowLoading,
  sendLaterLoading,
  isClearNoticeConfirmOpen,
  setIsClearNoticeConfirmOpen,
  clearForcedOutageNotice,
  turnOffEmailLoading,
  isOnEdit,
  mailFormDetails,
  frcdOutgEmailSent,
  enableEmailNotice,
  setIsEnableConfirmOpen,
  isEnableConfirmOpen,
  enableEmailLoading,
  handleOpen,
  confirmationSource,
  handleInProgressOpen,
  isForcedOutage,
}) => {
  const { state, actions } = useRealTimeLogContext();
  const tripsAndTargetsUpdated = useCallback(() => {
    let isUpdated = false;
    const restorationUpdated = (
      currrentData: IRealTimeLog,
      previousData: IRealTimeLog
    ) => {
      if (
        !isEqual(
          currrentData.logDtTmFrcdOutRestored,
          previousData.logDtTmFrcdOutRestored
        )
      ) {
        return true;
      }
      return false;
    };
    if (state?.data?.forcedLog) {
      if (confirmationSource === ConfirmationSource.UPDATE_ENTRY_LOG) {
        if (state.historyData?.forcedLog) {
          isUpdated =
            !restorationUpdated(state?.data, state?.historyData) &&
            !isEqual(state?.data?.forcedLog, state?.historyData?.forcedLog);
        } else {
          const fromList = state?.listHistory?.find(
            (val) => val.logId === state.data?.logId
          );
          isUpdated =
            !restorationUpdated(state?.data, fromList as IRealTimeLog) &&
            !isEqual(state?.data?.forcedLog, fromList?.forcedLog);
        }
      }
    }
    return isUpdated;
  }, [state, confirmationSource]);

  const ForcedOutageForm = (setOpen, handleEmailSubmit, isOpen) => (
    <ATMModal
      open={isOpen}
      size="tiny"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <ATMModal.Header>
        {frcdOutgEmailSent
          ? Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM_RESEND_TITLE
          : Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM_TITLE}
      </ATMModal.Header>
      <ATMModal.Content>
        <ATMForm
          ref={formRefEmail}
          onSubmit={handleConfirmEmailSubmit}
          mode="onChange"
          defaultValues={
            state.isFromInProgress &&
            confirmationSource === ConfirmationSource.IN_PROGRESS_UPDATE
              ? state?.data && generateRealTimeLogEmailFormDetails(state.data)
              : mailFormDetails
          }
          validationSchema={ForcedOutageConfirmationFormSchema}
        >
          {(props) => {
            return (
              <ATMFormProvider {...props}>
                <FormContent frcdOutgEmailSent={frcdOutgEmailSent} />
              </ATMFormProvider>
            );
          }}
        </ATMForm>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          type="button"
          secondary
          content={
            frcdOutgEmailSent || isOnEdit
              ? tripsAndTargetsUpdated()
                ? Lang.LBL_SEND_LATER
                : Lang.LBL_CANCEL
              : Lang.LBL_SEND_LATER
          }
          onClick={() => {
            if (isOnEdit) {
              setOpen(false);
              // if (setUpdatedInProgressLog && mailFormDetails) {
              //   setUpdatedInProgressLog(null);
              // }
              if (
                (confirmationSource === ConfirmationSource.UPDATE_ENTRY_LOG ||
                  confirmationSource ===
                    ConfirmationSource.IN_PROGRESS_UPDATE) &&
                (handleOpen || handleInProgressOpen)
              ) {
                handleOpen && handleOpen(false);
                handleInProgressOpen && handleInProgressOpen(false);
              }
            } else if (handleSendLater) {
              handleSendLater(logId);
            }
            actions.setIsFromInProgress(false);
          }}
          disabled={sendNowLoading || sendLaterLoading}
          loading={sendLaterLoading}
        />
        <ATMButton
          type="button"
          primary
          content={
            isOnEdit && !frcdOutgEmailSent
              ? Lang.LBL_SEND_EMAIL
              : frcdOutgEmailSent
              ? tripsAndTargetsUpdated()
                ? Lang.LBL_SEND_NOW
                : Lang.LBL_RESEND_EMAIL
              : Lang.LBL_SEND_NOW
          }
          onClick={() => {
            handleEmailSubmit();
            actions.setIsFromInProgress(false);
          }}
          disabled={sendNowLoading || sendLaterLoading}
          loading={sendNowLoading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );

  const NonForcedOutageForm = (setOpen, handleEmailSubmit, isOpen) => (
    <ATMModal
      open={isOpen}
      size="tiny"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <ATMModal.Header>
        {frcdOutgEmailSent
          ? Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM_RESEND_TITLE
          : Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM_TITLE}
      </ATMModal.Header>
      <ATMModal.Content>
        <ATMForm
          ref={formRefEmail}
          onSubmit={handleConfirmEmailSubmit}
          mode="onChange"
          defaultValues={
            state.isFromInProgress &&
            confirmationSource === ConfirmationSource.IN_PROGRESS_UPDATE
              ? state?.data && generateRealTimeLogEmailFormDetails(state.data)
              : mailFormDetails
          }
          validationSchema={ForcedOutageConfirmationFormSchema}
        >
          {(props) => {
            return (
              <ATMFormProvider {...props}>
                <FormContent frcdOutgEmailSent={frcdOutgEmailSent} />
              </ATMFormProvider>
            );
          }}
        </ATMForm>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          type="button"
          secondary
          content={
            frcdOutgEmailSent || isOnEdit
              ? tripsAndTargetsUpdated()
                ? Lang.LBL_SEND_LATER
                : Lang.LBL_CANCEL
              : state?.data?.logCategory === RealTimeLogCategory.WILDFIRE
              ? Lang.LBL_CANCEL
              : Lang.LBL_SEND_LATER
          }
          onClick={() => {
            if (isOnEdit) {
              setOpen(false);
              // if (setUpdatedInProgressLog && mailFormDetails) {
              //   setUpdatedInProgressLog(null);
              // }
              if (
                (confirmationSource === ConfirmationSource.UPDATE_ENTRY_LOG ||
                  confirmationSource ===
                    ConfirmationSource.IN_PROGRESS_UPDATE) &&
                (handleOpen || handleInProgressOpen)
              ) {
                handleOpen && handleOpen(false);
                handleInProgressOpen && handleInProgressOpen(false);
              }
            } else {
              if (
                state?.data?.logCategory === RealTimeLogCategory.WILDFIRE &&
                handleOpen
              ) {
                handleOpen(false);
              } else {
                if (handleSendLater) {
                  handleSendLater(logId);
                }
              }
            }
            actions.setIsFromInProgress(false);
          }}
          disabled={sendNowLoading || sendLaterLoading}
          loading={sendLaterLoading}
        />
        <ATMButton
          type="button"
          primary
          content={
            isOnEdit && !frcdOutgEmailSent
              ? Lang.LBL_SEND_EMAIL
              : frcdOutgEmailSent
              ? tripsAndTargetsUpdated()
                ? Lang.LBL_SEND_NOW
                : Lang.LBL_RESEND_EMAIL
              : Lang.LBL_SEND_NOW
          }
          onClick={() => {
            handleEmailSubmit();
            actions.setIsFromInProgress(false);
          }}
          disabled={sendNowLoading || sendLaterLoading}
          loading={sendNowLoading}
        />
      </ATMModal.Actions>
    </ATMModal>
  );

  let content: any = '';
  switch (confirmationSource) {
    case ConfirmationSource.ENABLE_EMAIL: {
      content = setIsEnableConfirmOpen &&
        enableEmailNotice &&
        isEnableConfirmOpen && (
          <ATMModal
            open={isEnableConfirmOpen}
            size="tiny"
            onClose={() => setIsEnableConfirmOpen(false)}
            onOpen={() => setIsEnableConfirmOpen(true)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <ATMModal.Header>
              {Lang.LBL_ENABLE_FORCED_OUTAGE_EMAIL_CONFIRM_TITLE}
            </ATMModal.Header>
            <ATMModal.Content>
              {Lang.LBL_ENABLE_FORCED_OUTAGE_EMAIL_CONFIRM}
            </ATMModal.Content>
            <ATMModal.Actions>
              <ATMButton
                type="button"
                secondary
                content={Lang.LBL_CANCEL}
                onClick={() => {
                  setIsEnableConfirmOpen(false);
                  actions.setIsFromInProgress(false);
                }}
                disabled={turnOffEmailLoading}
              />
              <ATMButton
                type="button"
                positive
                content={Lang.LBL_ENABLE_EMAIL}
                onClick={() => {
                  enableEmailNotice(logId);
                  actions.setIsFromInProgress(false);
                }}
                disabled={enableEmailLoading}
                loading={enableEmailLoading}
              />
            </ATMModal.Actions>
          </ATMModal>
        );
      break;
    }
    case ConfirmationSource.TURNED_OFF_EMAIL: {
      content = setIsClearNoticeConfirmOpen &&
        clearForcedOutageNotice &&
        isClearNoticeConfirmOpen && (
          <ATMModal
            open={isClearNoticeConfirmOpen}
            size="tiny"
            onClose={() => setIsClearNoticeConfirmOpen(false)}
            onOpen={() => setIsClearNoticeConfirmOpen(true)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <ATMModal.Header>
              {Lang.LBL_TURNOFF_FORCED_OUTAGE_EMAIL_CONFIRM_TITLE}
            </ATMModal.Header>
            <ATMModal.Content>
              {Lang.LBL_TURNOFF_FORCED_OUTAGE_EMAIL_CONFIRM}
            </ATMModal.Content>
            <ATMModal.Actions>
              <ATMButton
                type="button"
                secondary
                content={Lang.LBL_CANCEL}
                onClick={() => {
                  setIsClearNoticeConfirmOpen(false);
                  actions.setIsFromInProgress(false);
                }}
                disabled={turnOffEmailLoading}
              />
              <ATMButton
                type="button"
                negative
                content={Lang.LBL_TURNOFF_EMAIL}
                onClick={() => {
                  clearForcedOutageNotice(logId);
                  actions.setIsFromInProgress(false);
                }}
                disabled={turnOffEmailLoading}
                loading={turnOffEmailLoading}
              />
            </ATMModal.Actions>
          </ATMModal>
        );
      break;
    }
    case ConfirmationSource.UPDATE_ENTRY_LOG:
    case ConfirmationSource.ADD_ENTRY_LOG:
    case ConfirmationSource.IN_PROGRESS_UPDATE:
    case ConfirmationSource.SEND_RESEND_EMAIL: {
      if (!isForcedOutage) {
        content = NonForcedOutageForm(
          setIsForcedOutageConfirmOpen,
          handleConfirmEmailClick,
          isForcedOutageConfirmOpen
        );
      } else {
        content = ForcedOutageForm(
          setIsForcedOutageConfirmOpen,
          handleConfirmEmailClick,
          isForcedOutageConfirmOpen
        );
      }
      break;
    }
    default:
      break;
  }
  return content;
};

export default RealTimeLogMailConfirmationForm;
