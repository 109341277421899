/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ATMGrid,
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  IATMTabProps,
  ATMMenu,
  ATMButton,
  ATMPopover,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import { formatDate, format24hTime } from 'src/libraries/moment.library';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import Lang from 'src/libraries/language';
import history from 'src/history';
import {
  IRealTimeLogAudit,
  IRealTimeLogAuditAll,
} from 'src/models/real-time-log-audit.model';
import { formatToRawEmployeeId } from 'src/helpers/employee.helper';
import { RealTimeLogAuditCategory } from 'src/constants/real-time-log.constant';
import style from './real-time-log-audit-list.module.scss';
// import SickListList from '../../sick-list/sick-list-list/sick-list-list.component';
import RealTimeLogAuditAll from '../real-time-log-audit-all/real-time-log-audit-all.component';

type IProps = {
  data: IRealTimeLogAudit[];
  loading: boolean;
  downloadStatus: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleAll: (params: IORGDataTableQueryState) => void;
  total: number;
  today: number;
  currentTab: number;
  formRef: React.RefObject<HTMLFormElement>;
  handleSubmit: (data: IRealTimeLogAuditAll) => void;
  handleClick: () => void;
  setShowAuditTableAll: (x: boolean) => void;
  showAuditTableAll: boolean;
  handleDownload: () => void;
  setIsAuditSearch: React.Dispatch<React.SetStateAction<boolean>>;
  auditSearchFilters: Record<any, any>;
  setShowAuditAll: React.Dispatch<React.SetStateAction<boolean>>;
  showAuditAll: boolean;
  setAuditSearchFilters: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  // setSearchFilters?: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  // totalAll: number;
  setTotalAll: React.Dispatch<React.SetStateAction<number>>;
};

const RealTimeLogAuditListView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  handleAll,
  total,
  formRef,
  today,
  currentTab,
  handleClick,
  handleSubmit,
  showAuditTableAll,
  setShowAuditTableAll,
  handleDownload,
  setIsAuditSearch,
  auditSearchFilters,
  setShowAuditAll,
  showAuditAll,
  downloadStatus,
  setTotalAll,
}) => {
  const columns: IORGDataTableColumn<IRealTimeLogAudit>[] = [
    {
      index: 'realTimeLog.facility',
      title: Lang.LBL_LINE_SUBSTATION_FILE_SELECT,
      sortable: false,
      render: (_, value) => {
        return (
          <div className={style.listText}>
            {/* <b> */}
            {value.realTimeLog
              ? value.realTimeLog.facility
                ? value.realTimeLog.substation
                  ? `${value.realTimeLog.substation.name} - ${value.realTimeLog.facility.volt.voltNm}kv - ${value.realTimeLog.facility.outgFacNm}`
                  : `${value.realTimeLog.facility.outgFacNm}`
                : '--'
              : value.auditType ===
                RealTimeLogAuditCategory.BPOR_REPORT_EMAIL_SENT
              ? value.emailContent?.replace('.docx', '')
              : '--'}
            {/* </b> */}
          </div>
        );
      },
      width: '25%',
    },
    {
      index: 'auditEntry',
      title: Lang.LBL_AUDIT_ENTRY,
      sortable: false,
    },
    {
      index: 'updatedAt',
      title: Lang.LBL_AUDIT_TIMESTAMP,
      render: (value) => {
        return (
          <>
            <div className={style.listText}>
              <b>{format24hTime(value)}</b>
              <p>{formatDate(value)}</p>
            </div>
          </>
        );
      },
      width: '15%',
    },
    {
      index: 'emp.lastName',
      title: Lang.LBL_AUDIT_BY,
      sortable: false,
      width: '20%',
      render: (_, value) => {
        return (
          <>
            <div className={style.listText}>
              {/* <b> */}
              {value && value.emp
                ? value.emp.firstName && value.emp.lastName
                  ? `${value.emp.lastName}, ${
                      value.emp.firstName
                    } (${formatToRawEmployeeId(value.updatedBy)})`
                  : `Unavailable (${formatToRawEmployeeId(value.updatedBy)})`
                : `Unavailable (${formatToRawEmployeeId(value.updatedBy)})`}
              {/* </b> */}
            </div>
          </>
        );
      },
    },
  ];

  const table = (
    <>
      <ORGDataTable
        columns={columns}
        data={data}
        onChange={handleFetch}
        sortable
        loading={loading}
        toolbar="true"
        celled={false}
        location={history.location}
        handleLocation={history.push}
        showPagination={false}
        className={style.tableRealTimeAuditLogs}
        rowsPerPageOptions={[50]}
        filteredBy
      >
        {() => ({
          toolbars: {
            download: () => (
              <span className={style.selectables}>
                <ATMPopover
                  position="bottom left"
                  content="Download"
                  on="hover"
                  trigger={
                    <ATMButton
                      secondary
                      icon="download"
                      size="tiny"
                      floated="right"
                      onClick={handleDownload}
                      disabled={!data || (data && data.length === 0)}
                      loading={downloadStatus}
                    />
                  }
                />
              </span>
            ),
          },
          filters: ({ values, setValue }) => {
            return (
              <ATMGrid columns={3}>
                <ATMGrid.Row>
                  <ATMGrid.Column width={4}>
                    <label className={style.filterLbl}>
                      {Lang.LBL_AUDIT_TYPE}
                    </label>
                    <ATMDropdown
                      style={{ width: '100%' }}
                      size="small"
                      name="auditType"
                      selection
                      clearable
                      placeholder="Select"
                      value={values.auditType || ''}
                      options={[
                        ...Array.from(
                          Object.keys(Lang.REAL_TIME_LOG_AUDIT_CATEGORY)
                        ).map((val, i) => {
                          return {
                            key: i,
                            value: val,
                            text: Lang.REAL_TIME_LOG_AUDIT_CATEGORY[val],
                          };
                        }),
                      ]}
                      onChange={(_, val) => {
                        if (val.value) {
                          setValue('auditType', val.value.toString());
                        } else {
                          setValue('auditType', '');
                        }
                        return val.value;
                      }}
                      selectOnBlur={false}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
              </ATMGrid>
            );
          },
        })}
      </ORGDataTable>
      <span
        className={
          showAuditAll && today > 50 ? style.showBtn : style.hiddenEdit
        }
      >
        <ATMButton
          type="button"
          onClick={() => {
            handleAll({ page: 1, limit: 0 });
            setShowAuditAll(!showAuditAll);
          }}
          content="Show All Entries"
        />
      </span>
    </>
  );

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">
          {Lang.LBL_TODAY} ({today})
        </ATMMenu.Item>
      ),
      render: () => {
        return <div>{table}</div>;
      },
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">
          {Lang.LBL_ALL} ({total})
        </ATMMenu.Item>
      ),
      render: () => {
        // return (
        //   <>
        //     <div>{table}</div>
        //   </>
        // );
        return (
          <RealTimeLogAuditAll
            currentTab={currentTab}
            data={data}
            handleClick={handleClick}
            handleFetch={handleFetch}
            handleSubmit={handleSubmit}
            loading={loading}
            showAuditTableAll={showAuditTableAll}
            today={today}
            setShowAuditTableAll={setShowAuditTableAll}
            total={total}
            formRef={formRef}
            handleDownload={handleDownload}
            setIsAuditSearch={setIsAuditSearch}
            auditSearchFilters={auditSearchFilters}
            downloadStatus={downloadStatus}
            setTotalAll={setTotalAll}
          />
        );
      },
    },
  ];

  return (
    <div className={style.content}>
      <div className={style.headerTabs}>
        <Tabs
          name="realTimeLogAuditStatus"
          menu={{ pointing: true }}
          panes={panes}
        />
      </div>
    </div>
  );
};

export default RealTimeLogAuditListView;
