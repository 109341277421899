/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  ATMSegment,
  ATMGrid,
  ATMHeader,
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  IATMTabProps,
  ATMMenu,
  ATMButton,
  ATMCheckbox,
  ATMDropdown,
  ATMPopover,
  ATMDatePicker,
  ATMField,
} from 'shared-it-appmod-ui';
import { orderBy } from 'lodash';
import {
  formatDate,
  format24hTime,
  format24hDateTime,
} from 'src/libraries/moment.library';
import {
  IEmployeesRealTimeLog,
  IRealTimeLog,
  IRealTimeLogAll,
} from 'src/models/real-time-log.model';
import Tabs from 'src/components/atoms/tabs/tabs.component';
import Lang from 'src/libraries/language';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import {
  AccessRole,
  RealTimeInterruptionEmailQualified,
  RealTimeLogCategory,
} from 'src/constants';
import { getIsAdmin, hasRole } from 'src/libraries/access.library';
import history from 'src/history';
import TableSearch from 'src/components/atoms/table/table-search/table-search.component';
// import { getUser } from 'src/libraries/amplify.library';
import ATMBackToTop from 'src/components/atoms/back-to-top/back-to-top.component';
import RealTimeLogEdit from '../real-time-log-edit/real-time-log-edit.component';
import SickListList from '../../sick-list/sick-list-list/sick-list-list.component';
import style from './real-time-log-list.module.scss';
import TsoOssEmployeeList from '../../tso-oss-employee/tso-oss-employee-list/tso-oss-employee-list.component';
import TsoOssEmployeeAdd from '../../tso-oss-employee/tso-oss-employee-add/tso-oss-employee-add.component';
import RealTimeLogAdd from '../real-time-log-add/real-time-log-add.component';
import RealTimeLogListAllView from '../real-time-log-list-all/real-time-log-list-all.component';
import RealTimeLogListEmailSend from '../real-time-log-list-email-send/real-time-log-list-email-send.component';
import RealTimeLogListEmailTurnoff from '../real-time-log-list-email-turnoff/real-time-log-list-email-turnoff.component';
import RealTimeLogAuditList from '../../real-time-log-audit/real-time-log-audit-list/real-time-log-audit-list.component';
import RealTimeLogBPORReport from '../real-time-log-bpor-report/real-time-log-report.component';
import RealtimeLogInProgress from '../real-time-log-in-progress/real-time-log-in-progress.component';
import RealTimeLogSendBPORReport from '../real-time-log-bpor-report/real-time-log-bpor-report-send.component';
import { canEditRealTimeLog } from 'src/helpers/real-time-log.helper';

type IProps = {
  data: IRealTimeLog[];
  employees: IEmployeesRealTimeLog[];
  loading: boolean;
  reportLoading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  handleAll: (params: IORGDataTableQueryState) => void;
  total: number;
  // watchList: number;
  today: number;
  currentTab: number;
  isOpenEmployeeList: boolean;
  isOpenBporReport: boolean;
  isOpenAuditPage: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  handleOpenEmployeeList: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenBporReport: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenAuditPage: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (data: IRealTimeLogAll) => void;
  handleClick: () => void;
  handleTsoOssDownload: () => void;
  setShowTableAll: (x: boolean) => void;
  showTableAll: boolean;
  handleDownload: () => void;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searchFilters: Record<any, any>;
  setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
  showAll: boolean;
  setSearchFilters?: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  timer?: any;
  handleBporReport: (reportDate: Date) => void;
  showBporPreview?: boolean;
  setShowBporPreview?: React.Dispatch<React.SetStateAction<boolean>>;
  reportDate: Date;
  setReportDate: React.Dispatch<React.SetStateAction<Date>>;
  // setIsAddOpen: React.Dispatch<React.SetStateAction<boolean | null>>;
  // isAddOpen: boolean | null;
  setPreviousTab: React.Dispatch<React.SetStateAction<number>>;
  updatedInProgressLog: number | null;
  setUpdatedInProgressLog: React.Dispatch<React.SetStateAction<number | null>>;
  downloadLoading: boolean;
};

const RealTimeLogListView: React.FC<IProps> = ({
  data,
  loading,
  reportLoading,
  handleFetch,
  handleAll,
  total,
  formRef,
  today,
  currentTab,
  isOpenEmployeeList,
  isOpenBporReport,
  isOpenAuditPage,
  handleOpenEmployeeList,
  handleOpenBporReport,
  handleOpenAuditPage,
  handleClick,
  handleTsoOssDownload,
  handleSubmit,
  showTableAll,
  setShowTableAll,
  employees,
  handleDownload,
  setIsSearch,
  searchFilters,
  setShowAll,
  showAll,
  setSearchFilters,
  timer,
  handleBporReport,
  reportDate,
  setReportDate,
  // isAddOpen,
  // setIsAddOpen,
  setPreviousTab,
  updatedInProgressLog,
  setUpdatedInProgressLog,
  downloadLoading,
}) => {
  const { state: realtimeLogState, actions } = useRealTimeLogContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const [lineSubstation, setLineSubstation] = useState<string>('');
  const [editorContent, setEditorContent] = useState('');
  // const [loadingChangeLogUpdate, setLoadingChangeLogUpdate] =
  // useState<boolean>(false);

  useEffect(() => {
    if (substationState.list.length === 0) {
      substationActions.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [substationActions]);

  useEffect(() => {
    if (
      timer &&
      timer.current &&
      (isOpenEmployeeList || isOpenBporReport || isOpenAuditPage)
    ) {
      clearTimeout(timer.current);
    }
  }, [isOpenEmployeeList, isOpenBporReport, isOpenAuditPage]);

  const lineSubstationOptions = [
    {
      key: 'line',
      text: 'Line',
      value: 'line',
    },
    {
      key: 'substation',
      text: 'Substation',
      value: 'substation',
    },
  ];

  const columns: IORGDataTableColumn<IRealTimeLog>[] = [
    {
      index: 'logDtTm',
      title: Lang.LBL_DATE,
      render: (value) => {
        return (
          <>
            <div className={style.listText}>
              <b>{format24hTime(value)}</b>
              <p>{formatDate(value)}</p>
            </div>
          </>
        );
      },
      width: '10%',
    },
    {
      index: 'emp.lastName',
      title: Lang.LBL_NAME,
      width: '15%',
      render: (_, value) => {
        return (
          <>
            <div className={style.listText}>
              <b>
                {value.emp.firstName && value.emp.lastName
                  ? `${value.emp.lastName}, ${value.emp.firstName}`
                  : 'Unavailable'}
              </b>
              <p>
                {value.logUserType} {Lang.LBL_USER}
              </p>
            </div>
          </>
        );
      },
    },
    {
      index: 'logCategory',
      title: Lang.LBL_CATEGORY,
      width: '15%',
      render: (value) => {
        return (
          <>
            {value ? (
              <div className={style.listText}>
                <b>{value}</b>
              </div>
            ) : (
              '--'
            )}
          </>
        );
      },
    },
    {
      index: 'logEntry',
      title: Lang.LBL_DESCRIPTION,
      sortable: false,
      width: '55%',
      render: (_, value) => {
        return (
          <>
            <div>
              <b className={style.listText}>
                {value.facility && value.substation
                  ? `${value.substation?.name} - ${value.facility.volt?.voltNm}kv - ${value.facility.outgFacNm}`
                  : value.facility?.outgFacNm}
              </b>
            </div>
            <div className={style.preLine}>
              <span className={style.listText}>{value.logEntry}</span>
              {/* {value.logCategory &&
                value.logCategory !== RealTimeLogCategory.FORCEDOUTAGE && (
                  <div className={style.listText}>
                    <br />
                    <div>
                      <span>
                        <b>Occurence: </b>
                        {format24hDateTime(value.logDtTmFrcdOutOccurred)}
                      </span>
                    </div>
                    <div>
                      <span>
                        <b>Restoration: </b>
                        {value.logDtTmFrcdOutRestored
                          ? format24hDateTime(value.logDtTmFrcdOutRestored)
                          : ''}
                      </span>
                    </div>
                  </div>
                )} */}
              {value.logCategory === RealTimeLogCategory.FORCEDOUTAGE && (
                <div className={style.listText}>
                  <br />
                  <div>
                    <span>
                      <b>Occurence: </b>
                      {format24hDateTime(value.logDtTmFrcdOutOccurred)}
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Restoration: </b>
                      {value.logDtTmFrcdOutRestored
                        ? format24hDateTime(value.logDtTmFrcdOutRestored)
                        : ''}
                    </span>
                  </div>
                  <br />
                  <div>
                    <b>Subs Interrupted</b>
                  </div>
                  {value.forcedLogSubs && value.forcedLogSubs.length > 0 ? (
                    <>
                      {value.forcedLogSubs.map((val, key) => (
                        <>
                          <span key={key}>{val.substation.name}</span>
                          {value.forcedLogSubs &&
                          key < value.forcedLogSubs.length - 1 ? (
                            <span>, </span>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    '--'
                  )}
                  <br />
                  <br />
                  <div>
                    <b>Trips and Targets</b>
                  </div>
                  {value.forcedLog && value.forcedLog.length > 0 ? (
                    <>
                      {value.forcedLog.map((val, key) => (
                        <div key={key}>
                          <i>{val.substation.name}:</i> {val.tripsTargets}
                        </div>
                      ))}
                    </>
                  ) : (
                    '--'
                  )}
                  <br />
                  <br />
                  <div className={style.changeLog}>
                    <div>
                      <b>Additional Logs</b>
                    </div>
                    {value.changeLog && value.changeLog.length > 0 ? (
                      <>
                        {value.changeLog?.map((val, key) => (
                          <div key={key}>
                            {format24hDateTime(val.updatedAt)}
                            {' | '}
                            {val.emp?.lastName
                              ? `${val.emp?.lastName}, ${val.emp?.firstName}`
                              : val.updatedBy}
                            {' | '}
                            {val.changeLogNote}
                          </div>
                        ))}
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              )}
              {value.logCategory &&
                value.logCategory !== RealTimeLogCategory.FORCEDOUTAGE && (
                  <div className={style.listText}>
                    <br />
                    <div className={style.changeLog}>
                      <div>
                        <b>Additional Logs</b>
                      </div>
                      {value.changeLog && value.changeLog.length > 0 ? (
                        <>
                          {value.changeLog?.map((val, key) => (
                            <div key={key}>
                              {format24hDateTime(val.updatedAt)}
                              {' | '}
                              {val.emp?.lastName
                                ? `${val.emp?.lastName}, ${val.emp?.firstName}`
                                : val.updatedBy}
                              {' | '}
                              {val.changeLogNote}
                            </div>
                          ))}
                        </>
                      ) : (
                        '--'
                      )}
                    </div>
                  </div>
                )}
            </div>
          </>
        );
      },
    },
    {
      index: 'action',
      sortable: false,
      title: Lang.LBL_ACTION,
      width: '5%',
      cellProps: { textAlign: 'right' },
      render: (_, value) => {
        return (
          <>
            {/* <div
              className={
                getIsAdmin(AccessRole.REAL_TIME_LOG_UPDATER) &&
                ((value.logCategory === null &&
                    getUser()?.emp_no ===
                      value.logUserId?.replace(/^00/gm, '')))
                  ? ''
                  : style.hiddenEdit
              }
            >             */}
            <div
              className={
                canEditRealTimeLog(value.logCategory, value.logUserId)
                  ? ''
                  : style.hiddenEdit
              }
            >
              <RealTimeLogEdit
                data={value}
                showTableAll={false}
                setShowTableAll={setShowTableAll}
                setSearchFilters={setSearchFilters}
                currentTab={currentTab}
                timer={timer}
              />
            </div>
            <div
              style={{ textAlign: 'center' }}
              className={
                hasRole(AccessRole.BPOR_SENDER) &&
                Object.values(RealTimeInterruptionEmailQualified).includes(
                  value.logCategory as RealTimeInterruptionEmailQualified
                ) &&
                value.frcdOutgEmailSent !== false
                  ? ''
                  : style.hiddenEdit
              }
            >
              <ATMDropdown
                pointing
                icon="mail"
                className={value.frcdOutgEmailSent ? style.blue : style.red}
                closeOnChange
                closeOnBlur
              >
                <ATMDropdown.Menu>
                  <RealTimeLogListEmailSend
                    data={value}
                    frcdOutgEmailSent={value.frcdOutgEmailSent}
                    currentTab={currentTab}
                    timer={timer}
                    // setUpdatedInProgressLog={setUpdatedInProgressLog}
                  />
                  <RealTimeLogListEmailTurnoff logId={value.logId} />
                </ATMDropdown.Menu>
              </ATMDropdown>
            </div>
          </>
        );
      },
    },
  ];

  const table = (
    <>
      <ORGDataTable
        columns={columns}
        data={data}
        onChange={handleFetch}
        sortable
        loading={!data.length && loading}
        defaultState={currentTab === 0 ? searchFilters : {}}
        toolbar="true"
        celled={false}
        location={history.location}
        handleLocation={history.push}
        showPagination={false}
        className={style.tableRealTime}
        rowsPerPageOptions={[50]}
        filteredBy
      >
        {({ state, setState }) => ({
          toolbars: {
            text: () => (
              <span style={{ position: 'absolute', top: '60px', left: '35%' }}>
                {Lang.formatString(
                  Lang.NTE_REAL_TIME_LOG_TODAY,
                  format24hTime(new Date()) ?? ''
                )}
              </span>
            ),
            search: () => (
              <TableSearch
                field="logEntry"
                state={state}
                setState={setState}
                placeholder="Entry text"
                isEnter={Boolean(true)}
              />
            ),
            download: () => (
              <span className={style.selectables}>
                <ATMPopover
                  position="bottom left"
                  content="Download"
                  on="hover"
                  trigger={
                    <ATMButton
                      secondary
                      icon="download"
                      size="tiny"
                      floated="right"
                      onClick={handleDownload}
                      loading={downloadLoading}
                      disabled={downloadLoading}
                    />
                  }
                />
              </span>
            ),
          },
          filters: ({ values, setValue }) => {
            return (
              <ATMGrid columns={3}>
                <ATMGrid.Row>
                  <ATMGrid.Column width={4}>
                    <label className={style.filterLbl}>
                      {Lang.LBL_LINE_SUBSTATION}
                    </label>
                    <ATMDropdown
                      style={{ width: '100%' }}
                      size="small"
                      name="lineSubstation"
                      selection
                      clearable
                      placeholder="Select"
                      value={values.lineSubstation || ''}
                      options={lineSubstationOptions}
                      onChange={(_, val) => {
                        if (val.value) {
                          setLineSubstation(val.value.toString());
                          setValue('lineSubstation', val.value.toString());
                        } else {
                          setLineSubstation('');
                          setValue('lineSubstation', '');
                        }
                        return val.value;
                      }}
                      selectOnBlur={false}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={6} style={{ paddingLeft: '0px' }}>
                    <label className={style.filterLbl} />
                    <ATMDropdown
                      style={{
                        marginTop: '1.4rem',
                        width: '100%',
                      }}
                      size="small"
                      name={
                        lineSubstation === 'line'
                          ? 'logFacId'
                          : lineSubstation === 'substation'
                          ? 'logSubstation'
                          : 'no-name'
                      }
                      selection
                      clearable
                      placeholder="Select"
                      value={
                        lineSubstation === 'line'
                          ? Number(values.logFacId)
                          : values.logSubstation || ''
                      }
                      options={
                        lineSubstation === 'line'
                          ? realtimeLogState.line.map((value) => ({
                              key: value.outgFacId,
                              value: value.outgFacId,
                              text: value.outgFacNm,
                            }))
                          : lineSubstation === 'substation'
                          ? substationState.list.map((value) => ({
                              key: value.substationId,
                              value: value.substationId,
                              text: value.name,
                            }))
                          : []
                      }
                      onChange={(_, val) => {
                        if (lineSubstation === 'line') {
                          setValue('logFacId', val.value);
                          setValue('logSubstation', undefined);
                        } else if (lineSubstation === 'substation') {
                          setValue('logSubstation', val.value);
                          setValue('logFacId', undefined);
                        } else {
                          setValue('logSubstation', undefined);
                          setValue('logFacId', undefined);
                        }
                        return val.value;
                      }}
                      search
                      disabled={!values.lineSubstation}
                      selectOnBlur={false}
                    />
                  </ATMGrid.Column>
                  <ATMGrid.Column width={6} style={{ paddingLeft: '0px' }}>
                    <label className={style.filterLbl}>
                      {Lang.LBL_CATEGORY}
                    </label>
                    <ATMDropdown
                      style={{ width: '70%' }}
                      size="small"
                      name="logCategory"
                      selection
                      clearable
                      placeholder="Select"
                      value={values.logCategory || ''}
                      options={orderBy(
                        [
                          ...Array.from(
                            Object.keys(Lang.REAL_TIME_LOG_STATUS)
                          ).map((val) => {
                            return {
                              key: val,
                              value: val,
                              text: Lang.REAL_TIME_LOG_STATUS[val],
                            };
                          }),
                        ],
                        'text',
                        'asc'
                      )}
                      onChange={(_, val) => {
                        if (val.value) {
                          setValue('logCategory', val.value.toString());
                        } else {
                          setValue('logCategory', undefined);
                        }
                        return val.value;
                      }}
                      search
                      selectOnBlur={false}
                    />
                  </ATMGrid.Column>
                </ATMGrid.Row>
                <ATMGrid.Row>
                  <ATMGrid.Column width={16}>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <ATMCheckbox
                        label="My Entries"
                        style={{ margin: '0px 1em 0px 0px' }}
                        name="isMyEntries"
                        checked={values.isMyEntries || false}
                        onChange={(_, val) => {
                          setValue(
                            'isMyEntries',
                            Boolean(val.checked) || undefined
                          );
                          return Boolean(val.checked) || undefined;
                        }}
                      />
                      <ATMCheckbox
                        label="OSS Entries"
                        style={{ margin: '0px 1em 0px 1em' }}
                        name="isOssEntries"
                        checked={values.isOssEntries || false}
                        onChange={(_, val) => {
                          setValue(
                            'isOssEntries',
                            Boolean(val.checked) || undefined
                          );
                          return Boolean(val.checked) || undefined;
                        }}
                      />
                      <ATMCheckbox
                        label="TSO Entries"
                        style={{ margin: '0px 1em 0px 1em' }}
                        name="isTsoEntries"
                        checked={values.isTsoEntries || false}
                        onChange={(_, val) => {
                          setValue(
                            'isTsoEntries',
                            Boolean(val.checked) || undefined
                          );
                          return Boolean(val.checked) || undefined;
                        }}
                      />
                      <ATMCheckbox
                        label="BPOR"
                        style={{ margin: '0px 1em 0px 1em' }}
                        name="logBporReport"
                        checked={values.logBporReport || false}
                        onChange={(_, val) => {
                          setValue(
                            'logBporReport',
                            Boolean(val.checked) || undefined
                          );
                          return Boolean(val.checked) || undefined;
                        }}
                      />
                    </div>
                  </ATMGrid.Column>
                </ATMGrid.Row>
              </ATMGrid>
            );
          },
        })}
      </ORGDataTable>
      <span
        className={showAll && today > 50 ? style.showBtn : style.hiddenEdit}
      >
        <ATMButton
          type="button"
          onClick={() => {
            handleAll({ page: 1, limit: 0 });
            setShowAll(!showAll);
          }}
          content="Show All Entries"
        />
      </span>
    </>
  );

  const panes: IATMTabProps['panes'] = [
    {
      menuItem: (
        <ATMMenu.Item key="0">
          {Lang.LBL_TODAY} ({today})
        </ATMMenu.Item>
      ),
      render: () => {
        return (
          <>
            <div>{table}</div>
          </>
        );
      },
    },
    {
      menuItem: (
        <ATMMenu.Item key="1">
          {Lang.LBL_ALL} ({total})
        </ATMMenu.Item>
      ),
      render: () => {
        return (
          <RealTimeLogListAllView
            currentTab={currentTab}
            data={data}
            employees={employees}
            handleClick={handleClick}
            handleFetch={handleFetch}
            handleSubmit={handleSubmit}
            loading={loading}
            showTableAll={showTableAll}
            today={today}
            setShowTableAll={setShowTableAll}
            total={total}
            formRef={formRef}
            handleDownload={handleDownload}
            setIsSearch={setIsSearch}
            searchFilters={searchFilters}
            timer={timer}
            setPreviousTab={setPreviousTab}
            // setUpdatedInProgressLog={setUpdatedInProgressLog}
            downloadLoading={downloadLoading}
          />
        );
      },
    },
  ];

  return (
    <div className="admin-container">
      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column width={13}>
            <ATMSegment className={style.realTimeLogSection}>
              {!isOpenEmployeeList && !isOpenBporReport && !isOpenAuditPage ? (
                <>
                  <ATMGrid columns={2}>
                    <ATMGrid.Column verticalAlign="middle" width={5}>
                      <ATMHeader>{Lang.TTL_REAL_TIME_LOG}</ATMHeader>
                    </ATMGrid.Column>
                    <ATMGrid.Column textAlign="right" width={11}>
                      {hasRole([
                        AccessRole.BPOR_SENDER,
                        AccessRole.BPOR_VIEWER,
                      ]) && (
                        <ATMButton
                          id="open-audit"
                          type="button"
                          secondary
                          content={Lang.TTL_BPOR_AUDIT_LOGS}
                          onClick={() => handleOpenAuditPage(true)}
                          style={{ marginRight: '1em' }}
                        />
                      )}
                      {hasRole(AccessRole.BPOR_SENDER) && (
                        <ATMButton
                          id="open-bpor"
                          type="button"
                          secondary
                          content={Lang.TTL_BPOR_REPORT}
                          onClick={() => {
                            handleOpenBporReport(true);
                            actions.clearReport();
                            if (!reportDate) {
                              setReportDate(new Date());
                            }
                          }}
                          style={{ marginRight: '1em' }}
                        />
                      )}
                      {getIsAdmin(AccessRole.REAL_TIME_LOG_UPDATER) && (
                        <>
                          <ATMButton
                            id="open-employee"
                            type="button"
                            secondary
                            content={Lang.TTL_TSO_OSS_EMPLOYEE}
                            onClick={() => handleOpenEmployeeList(true)}
                            style={{ marginRight: '1em' }}
                          />
                          <RealTimeLogAdd
                            currentTab={currentTab}
                            timer={timer}
                            // isAddOpen={isAddOpen}
                            // setIsAddOpen={setIsAddOpen}
                          />
                        </>
                      )}
                    </ATMGrid.Column>
                  </ATMGrid>
                  <div className={style.content}>
                    <div className={style.headerTabs}>
                      <Tabs
                        name="realTimeLogStatus"
                        menu={{ pointing: true }}
                        panes={panes}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ATMGrid columns={2}>
                    <ATMGrid.Column verticalAlign="middle">
                      <ATMHeader>
                        {isOpenEmployeeList
                          ? Lang.TTL_TSO_OSS_EMPLOYEE
                          : isOpenBporReport
                          ? Lang.TTL_BPOR_REPORT
                          : Lang.TTL_BPOR_AUDIT_LOGS}
                      </ATMHeader>
                    </ATMGrid.Column>
                    <ATMGrid.Column textAlign="right">
                      {isOpenEmployeeList && (
                        <ATMPopover
                          position="bottom left"
                          content="Download"
                          on="hover"
                          trigger={
                            <ATMButton
                              key="download"
                              secondary
                              size="medium"
                              icon="download"
                              style={{
                                width: 35,
                                height: 35,
                                marginRight: '.5em',
                              }}
                              onClick={() => {
                                if (isOpenEmployeeList) {
                                  handleTsoOssDownload();
                                }
                              }}
                            />
                          }
                        />
                      )}
                      <ATMButton
                        type="button"
                        secondary
                        content={Lang.LBL_GO_BACK}
                        style={{ marginRight: '.5em' }}
                        onClick={() => {
                          if (isOpenEmployeeList) {
                            handleOpenEmployeeList(false);
                          }
                          if (isOpenBporReport) {
                            handleOpenBporReport(false);
                            actions.clearReport();
                          }
                          if (isOpenAuditPage) {
                            handleOpenAuditPage(false);
                          }
                          if (reportDate) {
                            setReportDate(new Date());
                          }
                        }}
                        icon="arrow left"
                      />
                      {getIsAdmin(AccessRole.REAL_TIME_LOG_UPDATER) &&
                        isOpenEmployeeList && <TsoOssEmployeeAdd />}
                      {isOpenBporReport && (
                        <>
                          <ATMField
                            as={ATMDatePicker}
                            className={style.inline}
                            isDateTimePicker
                            dateformat="MM/DD/YYYY"
                            // defaultValue={reportDate}
                            // clearOnSameDate={false}
                            value={reportDate}
                            name="reportDate"
                            onChange={(_, { value }) => {
                              if (value) {
                                handleBporReport(value);
                              }
                              setReportDate(value);
                              if (!value) {
                                actions.clearReport();
                              }
                              return value;
                            }}
                          />
                          <RealTimeLogSendBPORReport
                            editorContent={editorContent}
                            reportDate={reportDate}
                          />
                        </>
                      )}
                    </ATMGrid.Column>
                  </ATMGrid>
                  {isOpenEmployeeList && <TsoOssEmployeeList />}
                  {isOpenAuditPage && <RealTimeLogAuditList />}
                  {isOpenBporReport && (
                    <>
                      <RealTimeLogBPORReport
                        reportDate={reportDate as any}
                        loading={reportLoading}
                        editorContent={editorContent}
                        setEditorContent={setEditorContent}
                        handleBporReport={handleBporReport}
                      />
                    </>
                  )}
                </>
              )}
            </ATMSegment>
          </ATMGrid.Column>
          <ATMGrid.Column width={3} style={{ paddingLeft: 0 }}>
            <SickListList />
            <RealtimeLogInProgress
              currentTab={currentTab}
              timer={timer}
              showTableAll={showTableAll}
              updatedInProgressLog={updatedInProgressLog}
              setUpdatedInProgressLog={setUpdatedInProgressLog}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
      <div style={{ marginTop: '1em', marginBottom: '0.5em' }}>
        <ATMBackToTop />
      </div>
    </div>
  );
};

export default RealTimeLogListView;
